import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faMapMarkerAlt, faEuroSign } from '@fortawesome/free-solid-svg-icons'
import { navigate } from 'gatsby-link'
import showdown from 'showdown'

const scrollTo = (selector) => (
  typeof window !== `undefined` ?
    document.location.href === 'https://nathaliebruman-sophrologie.fr/' ?
      document.querySelector(selector).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
      :
      navigate(`/${selector}`)
    :
    null
);
const IndexPage = () => {
  const [openModal, setOpenModal] = useState(false);
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      allMarkdownRemark {
        edges {
          node {
            id
            frontmatter {
              titleHeader
              photoHeader
              approche {
                firstTextApproche
                secondTextApproche
                deroulementSeance {
                  bloc1DeroulementSeance
                  bloc2DeroulementSeance
                  bloc3DeroulementSeance
                  textDeroulementSeance
                }
              }
              infosTarifs
              citation {
                citation
                citationAuthor
              }
              sophrologie {
                firstTextSophrologie
                secondTextSophrologie
                sophrologieDetail
                Techniques {
                  bloc1Sophrologie
                  bloc2Sophrologie
                  bloc3Sophrologie
                }
              }
              about {
                photo
                textabout
              }
              ville
              tel
              adresse
              codepostal
              complementAdresse
              templateKey
            }
          }
        }
      }
    }
  `)
  const { edges } = data.allMarkdownRemark

  const converter = new showdown.Converter()

  return (
    <Layout>
      <SEO title="Sophrologue" />

      {edges.map(edge => (
        edge.node.frontmatter.templateKey === "home" && 
        <>
          <section className="hero is-fullheight is-home" style={{backgroundImage: `url(${edge.node.frontmatter.photoHeader})`}}>
            <div className="hero-header">
              <Header siteTitle={data.site.siteMetadata.title} />
            </div>
            <div className="hero-body">
              <div className="container has-text-centered">
                <h1 className="title is-size-1 has-text-white is-size-2-mobile">
                  {edge.node.frontmatter.titleHeader}
                </h1>
                <button className="is-rounded is-medium button is-medium" onClick={(e) => (scrollTo('#sophrologie'))}>Découvrir</button>
              </div>
            </div>
          </section>
          <section className="hero is-primary" id="sophrologie">
            <div className="hero-body">
              <div className="container">
                <div className="columns is-centered">
                  <div className="column is-10 has-text-white">
                    <h1 className="title has-text-white">
                      Qu’est-ce que la Sophrologie ?
                    </h1>
                    <div className="columns is-centered">
                      <div className="column is-9 is-12-tablet">
                        <div className="has-text-centered">
                          <div className="is-size-5 has-text-left mt-2" dangerouslySetInnerHTML={{ __html: converter.makeHtml(edge.node.frontmatter.sophrologie.firstTextSophrologie) }} />

                          <div className="columns mt-2">
                            <div className="column bloc-white">
                              <p className="has-text-black is-size-5 has-text-weight-semibold">{edge.node.frontmatter.sophrologie.Techniques.bloc1Sophrologie}</p>
                            </div>
                            <div className="column  bloc-white">
                              <p className="has-text-black is-size-5 has-text-weight-semibold">{edge.node.frontmatter.sophrologie.Techniques.bloc2Sophrologie}</p>
                            </div>
                            <div className="column bloc-white">
                              <p className="has-text-black is-size-5 has-text-weight-semibold">{edge.node.frontmatter.sophrologie.Techniques.bloc3Sophrologie}</p>
                            </div>
                          </div>
                          <div className="is-size-5 has-text-left mt-2 " dangerouslySetInnerHTML={{ __html: converter.makeHtml(edge.node.frontmatter.sophrologie.secondTextSophrologie) }} />
                          <button className="is-rounded is-medium button is-medium mt-2" onClick={e => setOpenModal(!openModal)}>En savoir plus</button>

                          <div className={openModal ? "modal is-active" : "modal"}>
                            <div className="modal-background" onClick={e => setOpenModal(!openModal)}></div>
                            <div className="modal-card">
                              <section className="modal-card-body is-primary has-text-left">
                                <h1 className="title">En savoir plus : </h1>
                                <div className="is-size-5 has-text-left mt-2 content" dangerouslySetInnerHTML={{ __html: converter.makeHtml(edge.node.frontmatter.sophrologie.sophrologieDetail) }}/>
                                <button className="modal-close is-large" aria-label="close" onClick={e => setOpenModal(!openModal)}></button>
                              </section>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-medium" id="a-propos">
            <div className="hero-body">
              <div className="container">
                <div className="columns is-centered">
                  <div className="column is-10">
                    <div className="columns is-centered">
                      <div className="column is-4 is-5-tablet">
                        <figure className="image is-3by3">
                          <img src={edge.node.frontmatter.about.photo} alt="imageprofil" />
                        </figure>
                      </div>
                      <div className="column mt-2 is-narrow bloc-text-about-column">
                        <h1 className="title mt-2 bloc-title-about" style={{ marginLeft: "120px", marginTop: "-60px", marginBottom: "60px" }}>
                          Qui suis-je ?
                        </h1>
                        <div className="bloc-text-about">
                          <div className="content" dangerouslySetInnerHTML={{ __html: converter.makeHtml(edge.node.frontmatter.about.textabout) }} />
                        </div>
                      </div>
                    </div>
                    <div className="has-text-centered">
                      <p className="is-size-4 has-text-weight-light"><i>{edge.node.frontmatter.citation.citation}</i></p>
                      <p className="has-text-right is-size-4 is-size-5-mobile">{edge.node.frontmatter.citation.citationAuthor}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-primary is-medium" id="mon-approche">
            <div className="hero-body">
              <div className="container">
                <div className="columns is-centered">
                  <div className="column is-10 has-text-white">
                    <h1 className="title mt-2 has-text-white">
                      Mon approche
                    </h1>
                    <div className="columns is-centered">
                      <div className="column is-9 mt-2 is-12-tablet">
                        <div className="has-text-centered">
                          <div className="bloc-white">               
                            <div className="has-text-black" dangerouslySetInnerHTML={{ __html: converter.makeHtml(edge.node.frontmatter.approche.firstTextApproche) }}/>
                          </div>
                          <p className="has-text-left is-size-4 mt-2 has-text-white">{edge.node.frontmatter.approche.deroulementSeance.textDeroulementSeance}</p>
                          <div className="bloc-white mt-1">
                            <div className="columns ">
                              <div className="column is-1">
                                <p className="has-text-black is-size-1 has-text-weight-bold" >1</p>
                              </div>
                              <div className="column has-text-left">
                                <div className="has-text-black content" dangerouslySetInnerHTML={{ __html: converter.makeHtml(edge.node.frontmatter.approche.deroulementSeance.bloc1DeroulementSeance) }} />
                              </div>
                            </div>
                          </div>
                          <div className="bloc-white">
                            <div className="columns is-vcentered">
                              <div className="column is-1">
                                <p className="has-text-black is-size-1 has-text-weight-bold">2</p>
                              </div>
                              <div className="column has-text-left">
                                <div className="has-text-black content" dangerouslySetInnerHTML={{ __html: converter.makeHtml(edge.node.frontmatter.approche.deroulementSeance.bloc2DeroulementSeance) }} />
                              </div>
                            </div>
                          </div>
                          <div className="bloc-white">
                            <div className="columns is-vcentered">
                              <div className="column is-1">
                                <p className="has-text-black is-size-1 has-text-weight-bold">3</p>
                              </div>
                              <div className="column has-text-left">
                                <div className="has-text-black content" dangerouslySetInnerHTML={{ __html: converter.makeHtml(edge.node.frontmatter.approche.deroulementSeance.bloc3DeroulementSeance) }} />
                              </div>
                            </div>
                          </div>
                          <div className="has-text-left mt-2 is-size-5" dangerouslySetInnerHTML={{ __html: converter.makeHtml(edge.node.frontmatter.approche.secondTextApproche) }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ))}

      {edges.map(edge => (
        edge.node.frontmatter.templateKey === "info" && 
        <section className="hero" >
          <div className="hero-body">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-10">
                  <h1 className="title">
                    Informations pratiques
                  </h1>
                  <div className="columns is-centered">
                    <div className="column is-3-desktop is-4-tablet" style={{ minWidth: "260px !important" }}>
                      <div className="has-text-centered">
                        <FontAwesomeIcon className="icon-more-informations" icon={faPhoneAlt} />
                      </div>
                      <div className="box has-text-centered box-more-information">
                        <br></br>
                        <p className="has-text-weight-semibold is-size-5 is-size-4-mobile">Prendre rendez-vous</p>
                        <div className="text-more-information">
                          <p>Consultations uniquement sur rendez-vous : </p>
                          <p className="has-text-weight-semibold is-size-6">{edge.node.frontmatter.tel}</p>
                        </div>
                        <a className="button is-rounded is-primary has-text-white" href="/contact">Plus d’informations</a>
                      </div>
                    </div>
                    <div className="column is-3-desktop is-4-tablet" style={{ minWidth: "260px !important" }}>
                      <div className="has-text-centered">
                        <FontAwesomeIcon className="icon-more-informations" icon={faEuroSign} />
                      </div>
                      <div className="box has-text-centered box-more-information">
                        <br></br>
                        <p className="has-text-weight-semibold is-size-5 is-size-4-mobile">Infos / Tarifs</p>
                        <div className="text-more-information">
                          {edges.map(edgeHome => (
                            edgeHome.node.frontmatter.templateKey === "home" && 
                              <div className="text-more-information" dangerouslySetInnerHTML={{ __html: converter.makeHtml(edgeHome.node.frontmatter.infosTarifs) }} />
                          ))}
                        </div>
                        <a className="button is-rounded is-primary has-text-white" href="/contact">En savoir plus</a>
                      </div>
                    </div>
                    <div className="column is-3-desktop is-4-tablet" style={{ minWidth: "260px !important" }}>
                      <div className="has-text-centered">
                        <FontAwesomeIcon className="icon-more-informations" icon={faMapMarkerAlt} />
                      </div>
                      <div className="box has-text-centered box-more-information">
                        <br></br>
                        <p className="has-text-weight-semibold is-size-5 is-size-4-mobile">Accéder au cabinet</p>
                        <div className="text-more-information">
                          <p>{edge.node.frontmatter.adresse} </p>
                          <p>{edge.node.frontmatter.complementAdresse}</p>
                          <p>{edge.node.frontmatter.codepostal} {edge.node.frontmatter.ville} </p>
                        </div>
                        <a className="button is-rounded is-primary has-text-white" href="/contact">Localiser le cabinet</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ))}
    </Layout>
  )

}

export default IndexPage
